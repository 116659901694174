* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

img {
  max-width: 100%;
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}