.wine-card__header {
    margin: 0 5px;
    background-color: #FFFFFF;
}

.wine-card__body {
    margin: 0 5px;
    padding: 20px;
    display: flex;
    align-items: center;
    background-color: #EFEBDF;
}

.wine-card__body input {
    display: none;
}

.wine-card__body label {
    font-family: "Quattrocento Sans", sans-serif;
    font-weight: 700;
    cursor: pointer;
}

.slick-arrow {
    display: none !important;
}

#prev, #next {
    height: 28px;
    width: 28px;
    /* center in Y */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    /* style content */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ae9068;
    border: 1px solid #ae9068;
    background-color: transparent;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.4s;
}

#prev:hover, #next:hover {
    background-color: #ae9068;
    color: #FFFFFF;
}

#prev {
    left: -35px;
}

#next {
    right: -35px;
}

@media screen and (max-width: 600px) {
    .wine-card__header, .wine-card__body {
        margin: 0 40px;
    }

    #prev {
        left: 0px;
    }
    
    #next {
        right: 0px;
    }
}