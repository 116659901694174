header {
    width: 100vw;
    height: 80px;
    padding-top: 10px;
    background-color: #ffffff;
    border-bottom: 7px solid #ae9068;
    font-size: 18px;
    font-family: "Quattrocento Sans", sans-serif;
}

nav {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    display: block;
}

.logo img {
    width: 180px;
}

.nav-menu {
    width: 100%;
    height: 0;
    margin: 0;
    padding: 0;
    position: fixed;
    top: 97px;
    left: 0;
    background-color: #ffffff;
    list-style: none;
    overflow: hidden;
    transition: all 0.5s;
}

.nav-menu li {
    padding: 25px 0;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s;
}

.nav-menu.open {
    height: 140px;
    box-shadow: 5px 0 15px rgba(0,0,0,0.15);
}

.nav-menu li:hover {
    background-color: #efebdf;
}

.nav-menu li a {
    text-decoration: none;
    color: #272727;
}

/* estilos para el botón hamburguesa */
#menu-icon {
    width: 35px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transform: rotateY(180deg);
}

#menu-icon div {
    height: 4px;
    background-color: #272727;
    margin: 6px 0;
    transition: all 0.4s;
    border-radius: 2px;
}

.bar-1 {
    width: 50%;
}

.open .bar-1 {
    width: 100%;
    -webkit-transform: rotate(-45deg) translate(-7px, 6px);
    transform: rotate(-45deg) translate(-7px, 6px);
}

.bar-2 {
    width: 75%;
}

.open .bar-2 {
    opacity: 0;
}

.bar-3 {
    width: 100%;
}

.open .bar-3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
}

@media screen and (min-width: 768px) {
    .nav-menu {
        height: 75px;
        position: relative;
        top: 0;
        display: flex;
        justify-content: flex-end;
        background-color: unset;
        transition: unset;
    }

    .nav-menu li {
        margin-left: 50px;
    }

    .nav-menu li:hover {
        background-color: #ffffff;
    }

    .nav-menu li a {
        transition: all 0.5s;
    }

    .nav-menu li:hover a {
        color: #ae9068;
    }

    #menu-icon {
        display: none;
    }
}
