body {
    max-width: 100vw;
    overflow-x: hidden;
}

.results {
    min-height: calc(100vh - 100px);
    padding: 80px 0;
    background-color: #faf9f9;
    background-image: url("/assets/deco.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

.results .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gold {
    color: #ae9068;
}

.results h1 {
    text-align: center;
    font-family: "Rufina";
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
}

.results h2 {
    max-width: 700px;
    margin: 32px auto 20px;
    text-align: center;
    font-family: "Rufina";
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
}

.results h3 {
    max-width: 700px;
    margin: 0 auto 40px;
    text-align: center;
    font-family: "Rufina";
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
}

@media screen and (max-width: 990px) {
    .results {
        padding: 60px 0;
    }

    .results h1 {
        font-size: 36px;
        line-height: 40px;
    }

    .results h2 {
        font-size: 28px;
        line-height: 34px;
    }

    .results h3 {
        font-size: 24px;
        line-height: 28px;
    }
}

@media screen and (max-width: 680px) {
    .results {
        padding: 50px 0;
    }

    .results h1 {
        font-size: 32px;
        line-height: 36px;
    }

    .results h2 {
        font-size: 24px;
        line-height: 28px;
    }

    .results h3 {
        font-size: 22px;
        line-height: 26px;
    }
}
