.form-container {
    height: 100vh;
    max-height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background properties */
    background-color: #ededed;
    background-image: url(/assets/background.png);
    background-repeat: no-repeat;
    background-size: auto 60vh;
    background-position: bottom right;
}

.form-title {
    margin-bottom: 32px;
    font-family: "Rufina";
    font-weight: 400;
    font-size: 60px;
    line-height: 92px;
}

@media screen and (max-width: 990px) {
    .form-title {
        font-size: 50px;
        line-height: 65px;
    }
}

@media screen and (max-width: 768px) {
    .form-container {
        background-size: contain;
    }

    .form-title {
        font-size: 40px;
        line-height: 54px;
    }
}
