.alert {
    width: 50%;
    margin-top: 5px;
    padding: 10px;
    border-radius: 2px;
    background-color: rgba(240, 40, 40, 0.2);
    font-family: "Quattrocento Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    animation: fadeIn 0.7s;
}

@media screen and (max-width: 990px) {
    .alert {
        width: 75%;
    }
}

@media screen and (max-width: 768px) {
    .alert {
        width: 100%;
    }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}