.congrats {
    display: flex;
}

.congrats > div {
    width: 50%;
}

.congrats > .frame {
    transform: translateY(-50%);
}

.points {
    margin-bottom: 32px;
    font-family: "Rufina";
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
}

.btn-group {
    display: flex;
    align-items: center;
}

.congrats .next-btn {
    margin-right: 30px;
    font-size: 16px;
    justify-content: center;
}

.congrats .next-btn svg {
    margin-left: 15px;
}

.congrats .link {
    display: block;
    color: #000;
    border: none;
    font-size: 16px;
    font-family: "Quattrocento Sans", sans-serif;
    text-decoration: underline;
    cursor: pointer;
    transition: all 0.4s;
}

.congrats a:hover {
    color: #ae9068;
}

@media screen and (max-width: 990px) {
    .congrats {
        flex-direction: column-reverse;
    }

    .congrats > div {
        width: 100%;
    }

    .congrats > div:first-child {
        margin-top: 50px;
    }

    .congrats > .frame {
        transform: translateY(0);
    }

    .points {
        font-size: 28px;
        line-height: 32px;
    }
}

@media screen and (max-width: 768px) {
    .btn-group {
        flex-direction: column;
        align-items: flex-start;
    }

    .congrats .link {
        margin-top: 30px;
    }
}