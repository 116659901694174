.step {
    display: none;
    animation-name: fadeIn;
    animation-duration: 0.75s;
}

.step.active {
    display: block;
}

.label {
    margin-bottom: 28px;
    display: block;
    font-family: "Quattrocento Sans", sans-serif;
    font-weight: 700;
}

.text-input {
    width: 50%;
    display: block;
    font-family: "Rufina";
    font-size: 25px;
    line-height: 50px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #ae9068;
}

.file-label {
    width: 270px;
    padding: 18px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Quattrocento Sans", sans-serif;
    font-weight: 700;
    background-color: #D9D9D9;
    cursor: pointer;
    transition: all 0.4s;
}

.file-label:hover {
    background-color: #CACACA;
}

.file-label svg {
    font-size: 22px;
}

.back-btn {
    width: 55px;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-family: "Quattrocento Sans", sans-serif;
    font-weight: 700;
    color: #ae9068;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: all 0.4s;
}

.next-btn {
    width: 270px;
    margin-top: 36px;
    padding: 18px 40px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: "Quattrocento Sans", sans-serif;
    font-weight: 700;
    color: #ae9068;
    background-color: #ededed;
    border: 1px solid #ae9068;
    cursor: pointer;
    transition: all 0.4s;
}

.next-btn:hover {
    background-color: #ae9068;
    color: #ffffff;
}

@media screen and (max-width: 990px) {
    .text-input {
        width: 75%;
    }
}

@media screen and (max-width: 768px) {
    .text-input {
        width: 100%;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}