* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

img {
  max-width: 100%;
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}
.alert {
    width: 50%;
    margin-top: 5px;
    padding: 10px;
    border-radius: 2px;
    background-color: rgba(240, 40, 40, 0.2);
    font-family: "Quattrocento Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    -webkit-animation: fadeIn 0.7s;
            animation: fadeIn 0.7s;
}

@media screen and (max-width: 990px) {
    .alert {
        width: 75%;
    }
}

@media screen and (max-width: 768px) {
    .alert {
        width: 100%;
    }
}

@-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
.step {
    display: none;
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
    -webkit-animation-duration: 0.75s;
            animation-duration: 0.75s;
}

.step.active {
    display: block;
}

.label {
    margin-bottom: 28px;
    display: block;
    font-family: "Quattrocento Sans", sans-serif;
    font-weight: 700;
}

.text-input {
    width: 50%;
    display: block;
    font-family: "Rufina";
    font-size: 25px;
    line-height: 50px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #ae9068;
}

.file-label {
    width: 270px;
    padding: 18px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Quattrocento Sans", sans-serif;
    font-weight: 700;
    background-color: #D9D9D9;
    cursor: pointer;
    transition: all 0.4s;
}

.file-label:hover {
    background-color: #CACACA;
}

.file-label svg {
    font-size: 22px;
}

.back-btn {
    width: 55px;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-family: "Quattrocento Sans", sans-serif;
    font-weight: 700;
    color: #ae9068;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: all 0.4s;
}

.next-btn {
    width: 270px;
    margin-top: 36px;
    padding: 18px 40px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: "Quattrocento Sans", sans-serif;
    font-weight: 700;
    color: #ae9068;
    background-color: #ededed;
    border: 1px solid #ae9068;
    cursor: pointer;
    transition: all 0.4s;
}

.next-btn:hover {
    background-color: #ae9068;
    color: #ffffff;
}

@media screen and (max-width: 990px) {
    .text-input {
        width: 75%;
    }
}

@media screen and (max-width: 768px) {
    .text-input {
        width: 100%;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.form-container {
    height: 100vh;
    max-height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background properties */
    background-color: #ededed;
    background-image: url(/assets/background.png);
    background-repeat: no-repeat;
    background-size: auto 60vh;
    background-position: bottom right;
}

.form-title {
    margin-bottom: 32px;
    font-family: "Rufina";
    font-weight: 400;
    font-size: 60px;
    line-height: 92px;
}

@media screen and (max-width: 990px) {
    .form-title {
        font-size: 50px;
        line-height: 65px;
    }
}

@media screen and (max-width: 768px) {
    .form-container {
        background-size: contain;
    }

    .form-title {
        font-size: 40px;
        line-height: 54px;
    }
}

.congrats {
    display: flex;
}

.congrats > div {
    width: 50%;
}

.congrats > .frame {
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.points {
    margin-bottom: 32px;
    font-family: "Rufina";
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
}

.btn-group {
    display: flex;
    align-items: center;
}

.congrats .next-btn {
    margin-right: 30px;
    font-size: 16px;
    justify-content: center;
}

.congrats .next-btn svg {
    margin-left: 15px;
}

.congrats .link {
    display: block;
    color: #000;
    border: none;
    font-size: 16px;
    font-family: "Quattrocento Sans", sans-serif;
    text-decoration: underline;
    cursor: pointer;
    transition: all 0.4s;
}

.congrats a:hover {
    color: #ae9068;
}

@media screen and (max-width: 990px) {
    .congrats {
        flex-direction: column-reverse;
    }

    .congrats > div {
        width: 100%;
    }

    .congrats > div:first-child {
        margin-top: 50px;
    }

    .congrats > .frame {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }

    .points {
        font-size: 28px;
        line-height: 32px;
    }
}

@media screen and (max-width: 768px) {
    .btn-group {
        flex-direction: column;
        align-items: flex-start;
    }

    .congrats .link {
        margin-top: 30px;
    }
}
.frame {
    display: flex;
    justify-content: center;
    align-items: center;
}

.frame div {
    position: relative;
}

.plane {
    width: 7%;
    position: absolute;
    top: -3%;
    left: 35%;
    -webkit-transform: rotate(25deg);
            transform: rotate(25deg);
    -webkit-animation: fly 2s linear 0.4s;
            animation: fly 2s linear 0.4s;
}

@-webkit-keyframes fly {
    0% {
        top: 33%;
        left: 7%;
    }
    12% {
        top: 30%;
        left: 8%;
        -webkit-transform: rotate(2deg);
                transform: rotate(2deg);
    }
    24% {
        top: 25%;
        left: 11%;
    }
    36% {
        top: 20%;
        left: 14%;
    }
    48% {
        top: 15%;
        left: 17%;
    }
    60% {
        top: 10%;
        left: 20%;
        -webkit-transform: rotate(6deg);
                transform: rotate(6deg);
    }
    72% {
        top: 4%;
        left: 25%;
        -webkit-transform: rotate(10deg);
                transform: rotate(10deg);
    }
    84% {
        top: 0%;
        left: 29%;
        -webkit-transform: rotate(17deg);
                transform: rotate(17deg);
    }
    100% {
        top: -3%;
        left: 35%;
        -webkit-transform: rotate(25deg);
                transform: rotate(25deg);
    }
}

@keyframes fly {
    0% {
        top: 33%;
        left: 7%;
    }
    12% {
        top: 30%;
        left: 8%;
        -webkit-transform: rotate(2deg);
                transform: rotate(2deg);
    }
    24% {
        top: 25%;
        left: 11%;
    }
    36% {
        top: 20%;
        left: 14%;
    }
    48% {
        top: 15%;
        left: 17%;
    }
    60% {
        top: 10%;
        left: 20%;
        -webkit-transform: rotate(6deg);
                transform: rotate(6deg);
    }
    72% {
        top: 4%;
        left: 25%;
        -webkit-transform: rotate(10deg);
                transform: rotate(10deg);
    }
    84% {
        top: 0%;
        left: 29%;
        -webkit-transform: rotate(17deg);
                transform: rotate(17deg);
    }
    100% {
        top: -3%;
        left: 35%;
        -webkit-transform: rotate(25deg);
                transform: rotate(25deg);
    }
}

.wine-card__header {
    margin: 0 5px;
    background-color: #FFFFFF;
}

.wine-card__body {
    margin: 0 5px;
    padding: 20px;
    display: flex;
    align-items: center;
    background-color: #EFEBDF;
}

.wine-card__body input {
    display: none;
}

.wine-card__body label {
    font-family: "Quattrocento Sans", sans-serif;
    font-weight: 700;
    cursor: pointer;
}

.slick-arrow {
    display: none !important;
}

#prev, #next {
    height: 28px;
    width: 28px;
    /* center in Y */
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    /* style content */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ae9068;
    border: 1px solid #ae9068;
    background-color: transparent;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.4s;
}

#prev:hover, #next:hover {
    background-color: #ae9068;
    color: #FFFFFF;
}

#prev {
    left: -35px;
}

#next {
    right: -35px;
}

@media screen and (max-width: 600px) {
    .wine-card__header, .wine-card__body {
        margin: 0 40px;
    }

    #prev {
        left: 0px;
    }
    
    #next {
        right: 0px;
    }
}
.checkbox {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    padding: 2px;
    display: inline-block;
    border: 1px solid #ae9068;
    border-radius: 3px;
    background-color: #FFFFFF;
}

.checkbox .checked {
    width: 100%;
    height: 100%;
    background-color: #ae9068;
    border-radius: 2px;
}
.loader {
    width: 8px;
    height: 40px;
    border-radius: 4px;
    display: block;
    margin: 20px auto;
    position: relative;
    background: currentColor;
    color: #fff;
    box-sizing: border-box;
    -webkit-animation: animloader 0.3s 0.3s linear infinite alternate;
            animation: animloader 0.3s 0.3s linear infinite alternate;
}

.loader::after,
.loader::before {
    content: "";
    width: 8px;
    height: 40px;
    border-radius: 4px;
    background: currentColor;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: 20px;
    box-sizing: border-box;
    -webkit-animation: animloader 0.3s 0.45s linear infinite alternate;
            animation: animloader 0.3s 0.45s linear infinite alternate;
}
.loader::before {
    left: -20px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
}

@-webkit-keyframes animloader {
    0% {
        height: 48px;
    }
    100% {
        height: 4px;
    }
}

@keyframes animloader {
    0% {
        height: 48px;
    }
    100% {
        height: 4px;
    }
}

.gnb-btn {
    padding: 10px;
    font-family: "Quattrocento Sans", sans-serif;
    font-weight: 700;
    border-radius: 0;
    color: #ae9068;
    border: 1px solid #ae9068;
    cursor: pointer;
}

.gnb-btn:first-of-type {
    margin-right: 10px;
}

.gnb-btn:hover, .gnb-btn.active {
    background-color: #ae9068;
    color: #ffffff;
}

.gnb-container {
    margin-top: 40px;
    -webkit-animation: fadeIn 0.6s linear;
            animation: fadeIn 0.6s linear;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
header {
    width: 100vw;
    height: 80px;
    padding-top: 10px;
    background-color: #ffffff;
    border-bottom: 7px solid #ae9068;
    font-size: 18px;
    font-family: "Quattrocento Sans", sans-serif;
}

nav {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    display: block;
}

.logo img {
    width: 180px;
}

.nav-menu {
    width: 100%;
    height: 0;
    margin: 0;
    padding: 0;
    position: fixed;
    top: 97px;
    left: 0;
    background-color: #ffffff;
    list-style: none;
    overflow: hidden;
    transition: all 0.5s;
}

.nav-menu li {
    padding: 25px 0;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s;
}

.nav-menu.open {
    height: 140px;
    box-shadow: 5px 0 15px rgba(0,0,0,0.15);
}

.nav-menu li:hover {
    background-color: #efebdf;
}

.nav-menu li a {
    text-decoration: none;
    color: #272727;
}

/* estilos para el botón hamburguesa */
#menu-icon {
    width: 35px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

#menu-icon div {
    height: 4px;
    background-color: #272727;
    margin: 6px 0;
    transition: all 0.4s;
    border-radius: 2px;
}

.bar-1 {
    width: 50%;
}

.open .bar-1 {
    width: 100%;
    -webkit-transform: rotate(-45deg) translate(-7px, 6px);
    transform: rotate(-45deg) translate(-7px, 6px);
}

.bar-2 {
    width: 75%;
}

.open .bar-2 {
    opacity: 0;
}

.bar-3 {
    width: 100%;
}

.open .bar-3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
}

@media screen and (min-width: 768px) {
    .nav-menu {
        height: 75px;
        position: relative;
        top: 0;
        display: flex;
        justify-content: flex-end;
        background-color: unset;
        transition: unset;
    }

    .nav-menu li {
        margin-left: 50px;
    }

    .nav-menu li:hover {
        background-color: #ffffff;
    }

    .nav-menu li a {
        transition: all 0.5s;
    }

    .nav-menu li:hover a {
        color: #ae9068;
    }

    #menu-icon {
        display: none;
    }
}

body {
    max-width: 100vw;
    overflow-x: hidden;
}

.results {
    min-height: calc(100vh - 100px);
    padding: 80px 0;
    background-color: #faf9f9;
    background-image: url("/assets/deco.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

.results .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gold {
    color: #ae9068;
}

.results h1 {
    text-align: center;
    font-family: "Rufina";
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
}

.results h2 {
    max-width: 700px;
    margin: 32px auto 20px;
    text-align: center;
    font-family: "Rufina";
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
}

.results h3 {
    max-width: 700px;
    margin: 0 auto 40px;
    text-align: center;
    font-family: "Rufina";
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
}

@media screen and (max-width: 990px) {
    .results {
        padding: 60px 0;
    }

    .results h1 {
        font-size: 36px;
        line-height: 40px;
    }

    .results h2 {
        font-size: 28px;
        line-height: 34px;
    }

    .results h3 {
        font-size: 24px;
        line-height: 28px;
    }
}

@media screen and (max-width: 680px) {
    .results {
        padding: 50px 0;
    }

    .results h1 {
        font-size: 32px;
        line-height: 36px;
    }

    .results h2 {
        font-size: 24px;
        line-height: 28px;
    }

    .results h3 {
        font-size: 22px;
        line-height: 26px;
    }
}

