.checkbox {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    padding: 2px;
    display: inline-block;
    border: 1px solid #ae9068;
    border-radius: 3px;
    background-color: #FFFFFF;
}

.checkbox .checked {
    width: 100%;
    height: 100%;
    background-color: #ae9068;
    border-radius: 2px;
}