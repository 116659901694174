.gnb-btn {
    padding: 10px;
    font-family: "Quattrocento Sans", sans-serif;
    font-weight: 700;
    border-radius: 0;
    color: #ae9068;
    border: 1px solid #ae9068;
    cursor: pointer;
}

.gnb-btn:first-of-type {
    margin-right: 10px;
}

.gnb-btn:hover, .gnb-btn.active {
    background-color: #ae9068;
    color: #ffffff;
}

.gnb-container {
    margin-top: 40px;
    animation: fadeIn 0.6s linear;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}