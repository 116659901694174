.frame {
    display: flex;
    justify-content: center;
    align-items: center;
}

.frame div {
    position: relative;
}

.plane {
    width: 7%;
    position: absolute;
    top: -3%;
    left: 35%;
    transform: rotate(25deg);
    animation: fly 2s linear 0.4s;
}

@keyframes fly {
    0% {
        top: 33%;
        left: 7%;
    }
    12% {
        top: 30%;
        left: 8%;
        transform: rotate(2deg);
    }
    24% {
        top: 25%;
        left: 11%;
    }
    36% {
        top: 20%;
        left: 14%;
    }
    48% {
        top: 15%;
        left: 17%;
    }
    60% {
        top: 10%;
        left: 20%;
        transform: rotate(6deg);
    }
    72% {
        top: 4%;
        left: 25%;
        transform: rotate(10deg);
    }
    84% {
        top: 0%;
        left: 29%;
        transform: rotate(17deg);
    }
    100% {
        top: -3%;
        left: 35%;
        transform: rotate(25deg);
    }
}
